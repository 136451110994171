import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <article aria-label="block_elements" className="container mx-auto prose">
  <div className="border-solid border mt-6 mb-10 border-gray-50 shadow-xl border p-6 px-10 rounded-lg">
    <h5 className="text-blue-500 uppercase w-full border-b border-blue-500 border-solid pb-2 font-bold mb-10">
      Lists
    </h5>
    <p>
      This is a paragraph before an <strong>unnumbered</strong> list (
      <code>ul</code>). Note that the spacing between a paragraph and a list
      before or after that is hard to tune in a user style sheet. You can't
      guess which paragraphs are logically related to a list, e.g. as a "list
      header". Also note that the following list keeps the default styling which
      is minimal.
    </p>
    <ul className="my-3">
      <li> One.</li>
      <li> Two.</li>
      <li>
        {" "}
        Three. Well, probably this list item should be longer. Note that for short
        items lists look better if they are compactly presented, whereas for long
        items, it would be better to have more vertical spacing between items.
      </li>
      <li>
        {" "}
        Four. This is the last item in this list. Let us terminate the list now without
        making any more fuss about it.
      </li>
    </ul>
    <p className="mt-4 mb-2">
      The following is a <code>menu</code> list:
    </p>
    <menu>
      <li> One.</li>
      <li> Two.</li>
      <li> Three.</li>
    </menu>
    <p className="mb-3">
      The following is a <code>dir</code> list:
    </p>
    <dir>
      <li>one/</li>
      <li>two/</li>
      <li>- three/</li>
      <li>- three/</li>
    </dir>
    <p className="mb-4">
      This is a paragraph before a <strong>numbered</strong> list (
      <code>ol</code>). Note that the spacing between a paragraph and a list
      before or after that is hard to tune in a user style sheet. You can't
      guess which paragraphs are logically related to a list, e.g. as a "list
      header".
    </p>
    <ol>
      <li> One.</li>
      <li> Two.</li>
      <li>
        {" "}
        Three. Well, probably this list item should be longer. Note that if items
        are short, lists look better if they are compactly presented, whereas for
        long items, it would be better to have more vertical spacing between items.
      </li>
      <li>
        {" "}
        Four. This is the last item in this list. Let us terminate the list now without
        making any more fuss about it.
      </li>
    </ol>
    <p className="mb-4">
      This is a paragraph before a <strong>definition</strong> list (
      <code>dl</code>). In principle, such a list should consist of{" "}
      <em>terms</em> and associated definitions. But many authors use{" "}
      <code>dl</code> elements for fancy "layout" things. Usually the effect is
      not <em>too</em> bad, if you design user style sheet rules for{" "}
      <code>dl</code>
      which are suitable for real definition lists.
    </p>
    <dl>
      <dt> recursion</dt>
      <dd> see recursion</dd>
      <dt> recursion, indirect</dt>
      <dd> see indirect recursion</dd>
      <dt> indirect recursion</dt>
      <dd> see recursion, indirect</dd>
      <dt> term</dt>
      <dd>
        {" "}
        a word or other expression taken into specific use in a well-defined meaning,
        which is often defined rather rigorously, even formally, and may differ quite
        a lot from an everyday meaning
      </dd>
    </dl>
  </div>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      